<template>
  <v-container  class="text-center ">
        <v-row align="center" justify="center" class="fill-height my-5 py-5">
          <v-col cols="12">
                <v-row class="fill-height my-5 py-5">
                  <v-col cols="12">
                    <v-img class="logo mx-auto" :src="require('@/assets/logo.png')"></v-img>
                  </v-col>
                  <v-col cols="12">
                    <h1 class="primary--text display-3">{{title}}</h1>
                  </v-col>
                  <v-col cols="12">
                    <h2 class="brandon mb-3">{{$t('pages.home.welcome')}}</h2>
                    <v-card max-width="130px" class="mx-auto rounded-card" :elevation="4" target="_blank" :href="deeplink">
                     <v-img class="mx-auto" :src="require('@/assets/images/app-logo.png')"></v-img>
                    </v-card>
                    <h2 class="brandon mb-3">{{$t('pages.home.download-app')}}</h2>
                  </v-col>  
                  <v-col cols="6" >
                    <a :href="download.android" target="_blank">
                      <v-img class="mx-auto logo" :src="require('@/assets/images/google-store.svg')"></v-img>
                    </a>
                  </v-col>
                  <v-col cols="6">
                    <a :href="download.ios" target="_blank">
                      <v-img class="mx-auto logo" :src="require('@/assets/images/apple-store.svg')"></v-img>
                    </a>
                  </v-col>
                </v-row>
          </v-col>
        </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Home',
  data: () => ({
    title: process.env.VUE_APP_BASE_NAME,
    deeplink: process.env.VUE_APP_DEEP_LINK,
    download: {
      android: "https://play.google.com/store/apps/details?id=com.atono.venchi",
      ios: "https://apps.apple.com/it/app/venchi-cioccolato-gelato/id1473955521"
    }
  }),
};
</script>

<style scoped>
.logo{
  max-width: 200px;
  position: relative;
}
.v-card.rounded-card{
    border-radius:30px;
}

</style>
